import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import DataControls from "../../components/DataTools/DataControls";
import { useLanguageContext } from "../../context/GlobalProvider";
import { getLocalStorage } from "../../services/LocalStorage";
import ReportService from "../../services/ReportService";

export default function CustomReport() {
  const { t } = useLanguageContext();
  const [selectedReportName, setSelectedReportrName] = useState(null);

  //Initial liste of hotel
  const [hotelListName, setHotelListName] = useState([]);
  //Selected hotel value
  const [selectedHotel, setSelectedHotel] = useState(null);

  //Initial year list and month list
  const [selectedYears, setSelectedYears] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  //Initial selected format
  const [selectedFormat, setSelectedFormat] = useState(null);
  const reportNameList = [
    { id: "GL_Extract", name: t("glreportNameListItem_1") },
    {
      id: "GL_Extract_Consolidated_By_Account",
      name: t("glbyAccountreportNameListItem_2"),
    },
    //{ id: "mpeComparativeReportPeriod", name: t("reportNameListItem_3") },
    // { id: "4", name: t("reportNameListItem_4") },
  ];

  //Initial showHotel id field
  const [showHotelIdInput, setShowHotelIdInput] = useState(false);

  const [displayedPrintReportsForm, setDisplayedPrintReportsForm] =
    useState(false);
  const monthsList = [
    { id: "1", name: "01" },
    { id: "2", name: "02" },
    { id: "3", name: "03" },
    { id: "4", name: "04" },
    { id: "5", name: "05" },
    { id: "6", name: "06" },
    { id: "7", name: "07" },
    { id: "8", name: "08" },
    { id: "9", name: "09" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
  ];

  const yearsList = [
    { id: "1", name: "2017" },
    { id: "2", name: "2018" },
    { id: "3", name: "2019" },
    { id: "4", name: "2020" },
    { id: "5", name: "2021" },
    { id: "6", name: "2022" },
    { id: "7", name: "2023" },
    { id: "8", name: "2024" },
    { id: "9", name: "2025" },
  ];
  const formatList = [
    { id: "xlsx", name: "excel" },
    { id: "pdf", name: "pdf" },
    { id: "csv", name: "csv" },
  ];

  const selectedHotelTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value  flex align-items-center">
          <img
            alt={option.name}
            src={`https://app.operandi.travel/images/logos/${option.logo}`}
            style={{ width: "20px", height: "20px" }}
            onError={e =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`border-circle  flag flag-${option.name.toLowerCase()}`}
          />
          <div className="selected-hotel-name">{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const hotelOptionTemplate = option => {
    return (
      <React.Fragment>
        <div className="country-item  flex align-items-center">
          <img
            alt={option.name}
            src={`https://app.operandi.travel/images/logos/${option.logo}`}
            style={{ width: "17px" }}
            onError={e =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.name.toLowerCase()}`}
          />
          <div
            className="hotel-name"
            style={{ marginLeft: "12px", color: option.pilot ? "red" : "" }}
          >
            {option.name}
          </div>
        </div>
      </React.Fragment>
    );
  };

  //Get hotel list
  useEffect(() => {
    setHotelListName(
      getLocalStorage("userHotels").map(item => ({
        id: item.hotelId,
        name: item.name,
        logo: item.logo,
        pilot: item.pilot,
      }))
    );
  }, []);

  const onHotelChange = e => {
    setSelectedHotel(e.target.value);
  };

  const onYearChange = e => {
    setSelectedYears(e.value);
  };

  function exportPdfReports() {
    const glExtractReportId = "GL_Extract";
    const GlExtractByAccountReportId = "GL_Extract_Consolidated_By_Account";
    let reportParamString = "";
    const groupHotel = getLocalStorage("groupHotel");
    const hotelId = getLocalStorage("defaultHotelId");
    if (selectedReportName === "GL_Extract") {
      reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId};Year=${selectedYears};Month=${selectedMonth}`;
      ReportService.printPdfReport(
        selectedReportName,
        "",
        glExtractReportId,
        reportParamString,
        selectedFormat
      );
    }

    if (selectedReportName === "GL_Extract_Consolidated_By_Account") {
      reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId};Year=${selectedYears};Month=${selectedMonth}`;
      ReportService.printPdfReport(
        selectedReportName,
        "",
        GlExtractByAccountReportId,
        reportParamString,
        selectedFormat
      );
    }
  }
  function exportExcelReports() {
    const glExtractReportId = "GL_Extract";
    const GlExtractByAccountReportId = "GL_Extract_Consolidated_By_Account";
    let reportParamString = "";
    const groupHotel = getLocalStorage("groupHotel");
    const hotelId = getLocalStorage("defaultHotelId");
    if (selectedReportName === "GL_Extract") {
      reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId};Year=${selectedYears};Month=${selectedMonth}`;
      ReportService.printExcelReport(
        selectedReportName,
        "",
        glExtractReportId,
        reportParamString,
        selectedFormat
      );
    }

    if (selectedReportName === "GL_Extract_Consolidated_By_Account") {
      reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId};Year=${selectedYears};Month=${selectedMonth}`;
      ReportService.printExcelReport(
        selectedReportName,
        "",
        GlExtractByAccountReportId,
        reportParamString,
        selectedFormat
      );
    }
  }
  function exportCsvReports() {
    const glExtractReportId = "GL_Extract";
    const GlExtractByAccountReportId = "GL_Extract_Consolidated_By_Account";
    let reportParamString = "";
    const groupHotel = getLocalStorage("groupHotel");
    const hotelId = getLocalStorage("defaultHotelId");
    if (selectedReportName === "GL_Extract") {
      reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId};Year=${selectedYears};Month=${selectedMonth}`;

      ReportService.printCsvReport(
        selectedReportName,
        "",
        glExtractReportId,
        reportParamString,
        selectedFormat
      );
    }

    if (selectedReportName === "GL_Extract_Consolidated_By_Account") {
      reportParamString += `Group_Hotels_Id=${groupHotel};Hotel_Id=${hotelId};Year=${selectedYears};Month=${selectedMonth}`;
      ReportService.printCsvReport(
        selectedReportName,
        "",
        GlExtractByAccountReportId,
        reportParamString,
        selectedFormat
      );
    }
  }

  useEffect(() => {
    if (
      selectedReportName === "GL_Extract_Consolidated_By_Account" ||
      selectedReportName === "GL_Extract"
    ) {
      setShowHotelIdInput(true);
      setDisplayedPrintReportsForm(true);
    }
  }, [selectedReportName]);

  function exportReports() {
    if (selectedFormat === "xlsx") {
      exportExcelReports();
    }
    if (selectedFormat === "pdf") {
      exportPdfReports();
    }
    if (selectedFormat === "csv") {
      exportCsvReports();
    }
  }

  return (
    <React.Fragment>
      <DataControls displayedImportExportDataControl="false" />

      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="p-fluid pr-0 md:pr-6">
              <div className="text-center text-xl font-bold">
                {/* {t("menuItem_10")} */}
              </div>
              <div className="field mb-3">
                <label htmlFor="reportName" className="font-medium">
                  {t("labelReportNameField")} *
                </label>
                <Dropdown
                  value={selectedReportName}
                  onChange={e => setSelectedReportrName(e.value)}
                  options={reportNameList}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("placeholderReportNameField")}
                  inputId="reportName"
                />
              </div>
              {showHotelIdInput ? (
                <div className="field mb-3">
                  <label htmlFor="selectedHotel" className="font-medium">
                    {t("labelhotelsField")} *
                  </label>
                  <Dropdown
                    placeholder={t("placeholderHotelListField")}
                    valueTemplate={selectedHotelTemplate}
                    itemTemplate={hotelOptionTemplate}
                    value={selectedHotel}
                    options={hotelListName}
                    onChange={onHotelChange}
                    optionLabel="name"
                    optionValue="id"
                    filter
                    filterBy="name"
                    filterMatchMode="startsWith"
                    name="hotelListDropDown"
                    inputId="selectedHotel"
                  ></Dropdown>
                </div>
              ) : null}

              {displayedPrintReportsForm ? (
                <>
                  <div className="field mb-3 grid formgrid p-fluid">
                    <div className="field mb-4 col-12 md:col-6">
                      <label htmlFor="selectedYears" className="font-medium">
                        {t("labelYearsReportsField")} *
                      </label>
                      <Dropdown
                        value={selectedYears}
                        onChange={onYearChange}
                        options={yearsList}
                        optionLabel="name"
                        optionValue="name"
                        placeholder={t("placeholderSelectYearField")}
                        inputId="selectedYears"
                      />
                    </div>

                    <div className="field mb-4 col-12 md:col-6">
                      <label htmlFor="selectedMonth" className="font-medium">
                        {t("labelMonthsReportsfield")} *
                      </label>
                      <Dropdown
                        value={selectedMonth}
                        onChange={e => setSelectedMonth(e.value)}
                        options={monthsList}
                        optionLabel="name"
                        optionValue="name"
                        placeholder={t("placeholderSelectMonthField")}
                        inputId="selectedMonth"
                      />
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label htmlFor="selectedFormat" className="font-medium">
                      {t("labelFormatField")} *
                    </label>
                    <Dropdown
                      value={selectedFormat}
                      onChange={e => setSelectedFormat(e.value)}
                      options={formatList}
                      optionLabel="name"
                      optionValue="id"
                      placeholder={t("placeholderFormatField")}
                      inputId="selectedFormat"
                    />
                  </div>
                </>
              ) : null}
              <Button
                type="submit"
                label={t("exportButton")}
                className="w-auto p-button-success"
                onClick={() => exportReports()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
