import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import TokenService from "../services/TokenService";
import BackNavigation from "./BackNavigation/BackNavigation";
import Account from "./Account/Account";
import Department from "./Departments/Department";
import BudgetVersions from "./budgetVersions/BudgetVersions";
import Budget from "./Budget/Budget";
import MemoTransaction from "./MemoTransaction/MemoTransaction";
import GroupKpi from "./GroupeKbis/GroupKpis";
import Kpis from "./Kpis/Kpis";
import Transaction from "./Transaction/Transaction";
import ComputeKpis from "./ComputeKpis/ComputeKpis";
import { clearLocalStorage, getLocalStorage } from "../services/LocalStorage";
import UploadJounral from "./Transaction/UploadJounral";
import KPIsAssignmentTable from "./KPIsAssignmentTable/KPIsAssignmentTable";
import LastImportPeriod from "./LastImportPeriod/LastImportPeriod";
//import OrgCharts from "./Kpis/OrgCharts";
import HttpTrace from "./HttpTrace/HttpTrace";
import ConcordanceReport from "./ConcordanceReport/ConcordanceReport";
import ListKpisMapping from "./Kpis/ListKpisMapping";
import CustomReport from "./CustomReport/CustomReport";
export default function ControlPanel(props) {
  const [selectedHotel, setSelectedHotel] = useState(
    getLocalStorage("defaultHotelId")
  );
  const [leftMenuState, setLeftMenuState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!TokenService.isAuth()) {
      navigate("/");
      clearLocalStorage();
    }
  });

  function handleLeftMenuState(visibleLeft) {
    setLeftMenuState(visibleLeft);
  }
  function handleSelectHotel(selectedHotel) {
    setSelectedHotel(selectedHotel);
  }

  const renderCurrentContent = () => {
    const components = {
      Account: Account,
      Department: Department,
      BudgetVersions: BudgetVersions,
      Budget: Budget,
      Transactions: Transaction,
      Memos: MemoTransaction,
      GroupKpi: GroupKpi,
      Kpis: Kpis,
      UploadJournal: UploadJounral,
      "Compute KPI's": ComputeKpis,
      KPIsAssignmentTable: KPIsAssignmentTable,
      LastImportPeriod: LastImportPeriod,
      ListKpisMapping: ListKpisMapping,
      HttpTrace: HttpTrace,
      ConcordanceReport: ConcordanceReport,
      CustomReport: CustomReport,
    };
    const Component = components[props.content];
    return <Component selectedHotel={selectedHotel} />;
  };

  return (
    <React.Fragment>
      {TokenService.isAuth() ? (
        <div
          className="lg:static"
          style={{ marginLeft: leftMenuState ? "255px" : "" }}
        >
          <div>
            <BackNavigation
              onLeftMenuStateChange={handleLeftMenuState}
              onHandleSelectHotel={handleSelectHotel}
              leftMenuState={leftMenuState}
              selectedHotel={selectedHotel}
            />
          </div>

          <div
            className="flex flex-column flex-auto surface-ground"
            //style={{ marginLeft: leftMenuState ? "255px" : "" }}
          >
            {renderCurrentContent()}
          </div>
        </div>
      ) : (
        <Link to="/" />
      )}
    </React.Fragment>
  );
}
