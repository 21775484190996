function getMenuItemName(t, index) {
  switch (index) {
    case 1:
      return t("menuItem_1");
    case 2:
      return t("menuItem_2");
    case 3:
      return t("menuItem_3");
    case 4:
      return t("menuItem_4");
    case 5:
      return t("menuItem_5");
    case 6:
      return t("menuItem_6");
    case 8:
      return t("menuItem_8");
    case 9:
      return t("menuItem_9");
    case 10:
      return t("menuItem_10");
    case 11:
      return <>{t("menuItem_11")} </>;

    case 12:
      return t("menuItem_12");
    case 13:
      return t("menuItem_13");
    case 14:
      return t("menuItem_16");
    case 15:
      return t("menuItem_15");
    case 16:
      return t("menuItem_12");
    case 17:
      return t("menuItem_17");

    default:
  }
}

export { getMenuItemName };
