import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useLayoutEffect, useEffect } from "react";

import Home from "./pages/Home";
import ControlPanel from "./pages/ControlPanel";
import Login from "./pages/Login/Login";
import ForgetPassword from "./pages/Password/ForgetPassword";
import laraLight from "./assets/themes/lara/lara-light/blue/theme.scss";
import ResetPassword from "./pages/Password/ResetPassword";
import UserProfile from "./pages/User/UserProfile";
//import "./assets/mythemes/lara/lara-dark/blue/theme.css" ;
import { clearLocalStorage } from "./services/LocalStorage";
import { Footer } from "./pages/Footer/Footer";
import { useLanguageContext } from "./context/GlobalProvider";
import LandingPage from "./pages/LandingPage";

function App(props) {
  const { t } = useLanguageContext();

  //Redirect to login Page
  useEffect(() => {
    if (window.location.pathname === "/") {
      clearLocalStorage();
    }
  }, []);

  useLayoutEffect(() => {
    document.title = t("applicationTitle");
  }, [t]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Login />} />

          <Route path="/UserProfile" element={<UserProfile />} />
          <Route path="/ForgetPassword" exact element={<ForgetPassword />} />
          <Route path="/reset_password" exact element={<ResetPassword />} />
          <Route path="/LandingPage" exact element={<LandingPage />} />
          <Route path="/Home" exact element={<Home />} />
          <Route
            path="/ControlPanel/Accounts"
            exact
            element={<ControlPanel content={"Account"} />}
          />
          <Route
            path="/ControlPanel/Departments"
            exact
            element={<ControlPanel content={"Department"} />}
          />
          <Route
            path="/ControlPanel/BudgetVersions"
            exact
            element={<ControlPanel content={"BudgetVersions"} />}
          />
          <Route
            path="/ControlPanel/Budget"
            exact
            element={<ControlPanel content={"Budget"} />}
          />
          <Route
            path="/ControlPanel/Transactions"
            exact
            element={<ControlPanel content={"Transactions"} />}
          />
          <Route
            path="/ControlPanel/Memos"
            exact
            element={<ControlPanel content={"Memos"} />}
          />
          <Route
            path="/ControlPanel/GroupKpi"
            exact
            element={<ControlPanel content={"GroupKpi"} />}
          />
          <Route
            path="/ControlPanel/UploadJournal"
            exact
            element={<ControlPanel content={"UploadJournal"} />}
          />
          <Route
            path="/ControlPanel/Kpis"
            exact
            element={<ControlPanel content={"Kpis"} />}
          />
          <Route
            path="/ControlPanel/ComputeKpi"
            exact
            element={<ControlPanel content={"Compute KPI's"} />}
          />
          <Route
            path="/ControlPanel/ConcordanceReport"
            exact
            element={<ControlPanel content={"ConcordanceReport"} />}
          />
          <Route
            path="/ControlPanel/CorrelationTable"
            exact
            element={<ControlPanel content={"CorrelationTable"} />}
          />
          <Route
            path="/ControlPanel/ListKpisMapping"
            exact
            element={<ControlPanel content={"ListKpisMapping"} />}
          />
          <Route
            path="/ControlPanel/OrgChartKpisMapping"
            exact
            element={<ControlPanel content={"OrgChartKpisMapping"} />}
          />
          <Route
            path="/ControlPanel/HttpTrace"
            exact
            element={<ControlPanel content={"HttpTrace"} />}
          />
          <Route
            path="/ControlPanel/KPIsAssignmentTable"
            exact
            element={<ControlPanel content={"KPIsAssignmentTable"} />}
          />
          <Route
            path="/ControlPanel/CustomReport"
            exact
            element={<ControlPanel content={"CustomReport"} />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
