import { getLocalStorage } from "../services/LocalStorage";
const LabelChange = (labelhotelField, labelHoldingField) => {
  const foundHotel = getLocalStorage("userHotels").find(
    el => el.status === 1 || el.status === 2
  );

  if (!foundHotel) return null;

  if (foundHotel.status === 1) {
    return labelhotelField;
  } else if (foundHotel.status === 2) {
    return labelHoldingField;
  }
};

export { LabelChange };
