import React, { useRef, useState, useEffect } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useLocation } from "react-router-dom";

import FilterBadge from "./FilterBadge";
import DataOptions from "./DataOptions";
import { useLanguageContext } from "../../context/GlobalProvider";

export default function DataControls(props) {
  const { pathname } = useLocation();
  const menu = useRef(null);
  const [pageTitle, setPageTitle] = useState("");
  const { t } = useLanguageContext();

  const titleMap = [
    { path: "/ControlPanel/Accounts", title: t("menuItem_1") },
    { path: "/ControlPanel/Departments", title: t("menuItem_2") },
    { path: "/ControlPanel/BudgetVersions", title: t("menuItem_3") },
    { path: "/ControlPanel/Budget", title: t("menuItem_4") },
    { path: "/ControlPanel/Transactions", title: t("menuItem_5") },
    { path: "/ControlPanel/Memos", title: t("menuItem_6") },
    { path: "/ControlPanel/UploadJournal", title: t("menuItem_8") },
    { path: "/ControlPanel/Kpis", title: t("menuItem_9") },
    { path: "/ControlPanel/ComputeKpi", title: t("menuItem_10") },
    { path: "/UserProfile", title: t("userProfileLinkTitle") },
    { path: "/ControlPanel/HttpTrace", title: t("menuItem_16") },
    { path: "/ControlPanel/ConcordanceReport", title: t("menuItem_15") },
    { path: "/ControlPanel/KPIsAssignmentTable", title: t("menuItem_12") },
    { path: "/ControlPanel/CustomReport", title: t("menuItem_17") },
  ];

  useEffect(() => {
    const curTitle = titleMap.find(item => item.path === pathname);
    if (curTitle && curTitle.title) {
      setPageTitle(curTitle.title);
    }
  }, [pathname, titleMap]);

  function leftToolbarTemplate() {
    return (
      <React.Fragment>
        <div
          className="grid "
          style={{
            color: "#495057",
            marginRight: "3.5rem",
            marginLeft: "-0.5rem",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          <div>{pageTitle}</div>
        </div>

        <FilterBadge
          filters={props.filters}
          onClick={props.onSearchButtonClick}
          onRemove={props.onFilterItemRemove}
          onClear={props.onFilterBadgeClear}
        ></FilterBadge>
      </React.Fragment>
    );
  }
  function rightToolbarTemplate() {
    if (props.displayedDataControl === "true") {
      return (
        <React.Fragment>
          <Tooltip target=".custom-target-icon" />
          <div className="grid align-items-center">
            {props.addButtonLabel !== "null" && (
              <div className="col" id="add-account-button-for-account-page">
                <Button
                  label={props.addButtonLabel}
                  icon="pi pi-plus"
                  className="p-button-success"
                  id="save-button-for-account-page"
                  onClick={() => props.onAddButtonClick()}
                />
              </div>
            )}

            <div className="col" id="filter-button-for-account-page">
              {props.searchButtonlabel === "Search" ? (
                <span
                  className="cursor-pointer "
                  onClick={() => props.onSearchButtonClick()}
                >
                  <i
                    data-pr-position="top"
                    data-pr-tooltip={t("searchTooltipMessage")}
                    style={{ color: "rgb(73, 80, 87)" }}
                    className="custom-target-icon custom-target-icon pi pi-filter cursor-pointer font-bold"
                  ></i>
                  <span
                    className="font-bold"
                    style={{ color: "rgb(73, 80, 87)" }}
                  >
                    {t("searchButton")}
                  </span>
                </span>
              ) : (
                <span>
                  <i
                    data-pr-position="top"
                    data-pr-tooltip={t("filterTooltipMessage")}
                    style={{ color: "rgb(73, 80, 87)" }}
                    className="custom-target-icon custom-target-icon pi pi-filter cursor-pointer font-bold"
                    onClick={() => props.onSearchButtonClick()}
                  >
                    {t("filterSidebarTitle")}
                  </i>
                </span>
              )}
            </div>
            {props.displayedImportExportDataControl === "true" && (
              <div className="col" id="filter-button-for-account-page">
                <span>
                  <i
                    //data-pr-at="ml-2"
                    data-pr-position="top"
                    data-pr-tooltip={t("exprotImportTooltipMessage")}
                    className="custom-target-icon pi pi-ellipsis-h cursor-pointer"
                    onClick={event => {
                      menu.current.toggle(event);
                    }}
                  ></i>
                </span>
              </div>
            )}
          </div>

          <DataOptions
            displayedImportDataControl={props.displayedImportDataControl}
            menu={menu}
            onImportFileClick={props.onImportFileClick}
            exportCsvFile={props.exportCSV}
          />
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="col-12  mt-0">
        <Toolbar
          className="mb-0  border-none"
          right={rightToolbarTemplate}
          left={leftToolbarTemplate}
        ></Toolbar>
      </div>
    </React.Fragment>
  );
}
