import React from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";

import { getLocalStorage } from "../../services/LocalStorage";
import { useLanguageContext } from "../../context/GlobalProvider";
import { LabelChange } from "../../utils/CustomLabelChange";

export default function Profile(props) {
  const { firstName, lastName } = props.userData;
  const { visibleProfile, onProfileHide } = props;

  const { t } = useLanguageContext();

  const customHeaderProfileSidebar = (
    <React.Fragment>
      <div className="grid grid-nogutter  w-full">
        <div className="col">
          {/* <button
            type="button"
            className="p-sidebar-close p-sidebar-icon p-link"
            aria-label="close"
            onClick={props.onProfileHide}
          >
            <span className="p-sidebar-close-icon pi pi-times"></span>
          </button> */}
        </div>

        <div className="col-6  text-right">
          <span
            className="cursor-pointer p-sidebar-close-icon pi pi-times text-700 font-bold"
            onClick={props.onProfileHide}
          ></span>
          {/* <button
            type="button"
            className="p-link"
            aria-label="sign-out"
            onClick={props.logout}
          >
            <span
              className="sign-out-icon pi pi-power-off pi-fw"
              aria-required="true"
              style={{ marginRight: "10px" }}
            ></span>
            <span className="sign-out-icon">{t("signOutButton")}</span>
          </button> */}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Sidebar
      icons={customHeaderProfileSidebar}
      modal={false}
      showCloseIcon={false}
      maskClassName="profile-sidebar-mask-classname"
      //blockScroll="true"
      className="profileSidebar"
      position="right"
      visible={visibleProfile}
      onHide={onProfileHide}
    >
      <div className="surface-card p-0  border-round flex-auto">
        <div className="flex gap-5 flex-column-reverse md:flex-row">
          <div className="flex flex-column align-items-center flex-or">
            <img
              src={`https://app.operandi.travel/images/logos/${getLocalStorage(
                "pilotHotelLogo"
              )}`}
              alt="user logo"
            />
          </div>
          <div className="flex-auto p-fluid">
            <div className="mb-2 font-bold">
              {firstName} {lastName}
            </div>
            <div className="mb-2">{getLocalStorage("username")}</div>
            <div className="mb-2">
              {LabelChange(
                t("userProfileHotelName"),
                t("userHoldingHotelName")
              )}
              {getLocalStorage("pilotHotelName")}
            </div>
            <div className="mb-2">
              {t("userProfileOrganization")} {getLocalStorage("groupHotelName")}
            </div>
          </div>
        </div>
        <div className="preference_selection grid  border-round-3xl flex justify-content-between flex-wrap">
          <Link
            to="/UserProfile"
            className="account-link ml-2 no-underline text-800"
          >
            <div className="my-profile-preference_selection col cursor-pointer  border-round-3xl flex align-items-center justify-content-center ">
              <div className="text-center p-3 border-round-sm   font-bold">
                <i
                  className="pi pi-user pi-fw text-700"
                  style={{ marginRight: "10px" }}
                ></i>
                <span className="text-700">{t("userProfileLinkTitle")}</span>
              </div>
            </div>
          </Link>

          <div
            className="signout-preference_selection col cursor-pointer  border-round-3xl flex align-items-center justify-content-center "
            onClick={props.logout}
          >
            <div className=" cursor-pointer text-center p-3 border-round-sm  font-bold">
              <div onClick={props.logout}>
                <i
                  className="sign-out-icon pi pi-power-off pi-fw text-700"
                  style={{ marginRight: "10px" }}
                ></i>
                <span className="sign-out-icon text-700">
                  {t("signOutButton")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
